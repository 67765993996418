import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import { gsap } from "gsap";
import Banner from "./Banner";
import Timelines from "./Timelines";
import Projects from "./Projects";
import Contact from "./Contact";

const SectionWipes2Styled = styled.div`
  overflow: hidden;
  #pinContainer {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  #pinContainer .panel {
    height: 100vh;
    width: 100vw;
    position: absolute;
    text-align: center;
  }

  .panel.blue {
    background-color: #3aafa9;
  }

  .panel.turqoise {
    background-color: #17252a;
  }

  .panel.green {
    background-color: #211c3e;
  }

  .panel.bordeaux {
    background-color: #def2f1;
  }
`;

const MainContent = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width > 786 ? (
    <SectionWipes2Styled>
      <Controller>
        <Scene triggerHook={0} duration="500%" pin>
          <Timeline wrapper={<div id="pinContainer" />}>
            <section className="panel blue">
              <Banner />
            </section>
            <Tween id="timeline" from={{ x: "-100%" }} to={{ x: "0%" }}>
              <section className="panel turqoise">
                <Timelines />
              </section>
            </Tween>
            <Tween from={{ x: "100%" }} to={("#anchor", { x: "0%" })}>
              <section className="panel green">
                <Projects />
              </section>
            </Tween>
            <Tween id="anchor" from={{ y: "-100%" }} to={{ y: "0%" }}>
              <section className="panel bordeaux">
                <Contact />
              </section>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    </SectionWipes2Styled>
  ) : (
    <div>
      <Banner />
      <Timelines />
      <Projects />
      <Contact />
    </div>
  );
};

export default MainContent;
