/*
First page seen by user

Hello my name is Florian
I'm a Fullstack developer
 */
import React from "react";
import "../stylesheet/Banner.css";
import Navbar from "./Navbar";

const Banner = () => {
  return (
    <div className="banner">
      {/* <Navbar /> */}
      <div className="banner__container">
        <div className="banner__left">
          {/* <div className="banner__logo">{"{"}</div> */}
        </div>
        <div className="banner__right">
          {/* <div className="banner__logo">{"/>"}</div> */}
        </div>
        <div className="banner__center">
          <div className="banner__title">
            <span>H</span>
            <span className="active">e</span>
            <span>l</span>
            <span>l</span>
            <span>o</span>
            I'm Flo,
          </div>
          <div className="banner_subtitle">
            <div className="banner_subtitle--text">A fullstack developer</div>
            <div className="banner_subtitle--container">
              <div className="banner_subtitle--left">
                {"{ "}.NET{" }"}
              </div>
              <div className="banner_subtitle--center">+</div>
              <div className="banner_subtitle--right">
                {"< "}ReactJS {" />"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
