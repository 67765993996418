/*
Contact me page
 */
import React, { Component } from "react";
import "../stylesheet/Contact.css";

export class Contact extends Component {
  static propTypes = {};

  render() {
    return (
      <div>
        <div className="background">
          <div className="container">
            <div className="screen">
              <div className="screen-header">
                <div className="screen-header-left">
                  <div className="screen-header-button close"></div>
                  <div className="screen-header-button maximize"></div>
                  <div className="screen-header-button minimize"></div>
                </div>
                <div className="screen-header-right">
                  <div className="screen-header-ellipsis"></div>
                  <div className="screen-header-ellipsis"></div>
                  <div className="screen-header-ellipsis"></div>
                </div>
              </div>
              <div className="screen-body">
                <div className="screen-body-item left">
                  <div className="app-title">
                    <span>CONTACT</span>
                    <span>ME</span>
                  </div>
                </div>
                <div className="screen-body-item">
                  <div className="app-contact">
                    CONTACT INFO : <span>+33 6 56 79 62 82</span>
                  </div>
                  <div className="app-contact">
                    EMAIL : <span>florianfanor@gmail.com</span>
                  </div>
                  {/* <form onSubmit={(val) => console.log(val)}>
                    <div className="app-form">
                      <div className="app-form-group">
                        <input
                          className="app-form-control"
                          placeholder="NAME"
                          value="Florian Fanor"
                          readOnly
                        />
                      </div>
                      <div className="app-form-group">
                        <input
                          className="app-form-control"
                          placeholder="EMAIL"
                        />
                      </div>
                      <div className="app-form-group">
                        <input
                          className="app-form-control"
                          placeholder="CONTACT NO"
                        />
                      </div>
                      <div className="app-form-group message">
                        <input
                          className="app-form-control"
                          placeholder="MESSAGE"
                        />
                      </div>
                      <div className="app-form-group buttons">
                        <input
                          className="app-form-button"
                          type="submit"
                          value="CANCEL"
                        />
                        <input
                          className="app-form-button"
                          type="submit"
                          value="SEND"
                        />
                      </div>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
