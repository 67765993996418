/*
List of all personal projects 
 */
import React, { Component, useEffect } from "react";
import "../stylesheet/ProjectCards.css";
import "../stylesheet/Projects.css";
import Particles from "react-particles-js";

const Projects = () => {
  // ParticlesJS Config.

  useEffect(() => {}, []);

  return (
    <div>
      <Particles
        params={{
          particles: {
            number: {
              value: 50,
            },
            size: {
              value: 3,
            },
            color: {
              value: "#3aafa9",
            },
          },

          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />

      <div className="text">
        <div className="projects__container">
          <div className="content-wrapper">
            <div className="news-card">
              <a
                href="https://www.pampers.com/en-us"
                className="news-card__card-link"
              ></a>
              <img
                src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/6d01bf9840733.560db0b5e841c.jpg"
                alt=""
                className="news-card__image"
              />
              <div className="news-card__text-wrapper">
                <h2 className="news-card__title">Pampers.com</h2>
                <div className="news-card__post-date">2017</div>
                <div className="news-card__details-wrapper">
                  <p className="news-card__excerpt">
                    Developped and maintain the sites for the 13th different
                    countries, the technology used was ASP.NE MVC, Html, Css and
                    Javascript.
                  </p>
                  {/* <a href="#" className="news-card__read-more">
                    Read more <i className="fas fa-long-arrow-alt-right"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div className="news-card">
              <a
                href="https://www.unireso.com/"
                className="news-card__card-link"
              ></a>
              <img
                src="https://www.unireso.com/wp-content/uploads/2020/12/facebook_1200x627px_image_partagee_lien.jpg"
                alt=""
                className="news-card__image"
              />
              <div className="news-card__text-wrapper">
                <h2 className="news-card__title">Unireso</h2>
                <div className="news-card__post-date">2018</div>
                <div className="news-card__details-wrapper">
                  <p className="news-card__excerpt">
                    Built in angular and ASP.NET Core, this was a project
                    realised for a client. The main aim of the app was to solve
                    the problem of distribution of shares, cost, dividend to the
                    different partners.
                  </p>
                  {/* <a href="#" className="news-card__read-more">
                    Read more <i className="fas fa-long-arrow-alt-right"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div className="news-card">
              <a
                href="https://www.bulma-solution.com/"
                className="news-card__card-link"
              ></a>
              <img
                src="https://www.frenchweb.fr/wp-content/uploads/2020/09/article-French_Web.jpg"
                alt=""
                className="news-card__image"
              />
              <div className="news-card__text-wrapper">
                <h2 className="news-card__title">Bulma Solution</h2>
                <div className="news-card__post-date">2019</div>
                <div className="news-card__details-wrapper">
                  <p className="news-card__excerpt">
                    An HR assistant built in ReactJS and React Native, with a
                    backend server in python django.
                  </p>
                  {/* <a href="#" className="news-card__read-more">
                    Read more <i className="fas fa-long-arrow-alt-right"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div className="news-card">
              <a href="" className="news-card__card-link"></a>
              <img
                src="http://www.riwhelpdesk.com/wp-content/uploads/2015/05/help-desk-banner.png"
                alt=""
                className="news-card__image"
              />
              <div className="news-card__text-wrapper">
                <h2 className="news-card__title">
                  Ticketing management system
                </h2>
                <div className="news-card__post-date">2017</div>
                <div className="news-card__details-wrapper">
                  <p className="news-card__excerpt">
                    A project built from scratch in PHP symfony during my end of
                    year project.
                  </p>
                  {/* <a href="#" className="news-card__read-more">
                    Read more <i className="fas fa-long-arrow-alt-right"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div className="news-card">
              <a
                href="https://meetme-f75d3.web.app/"
                className="news-card__card-link"
              ></a>
              <img
                src="https://www.logo.wine/a/logo/Tinder_(app)/Tinder_(app)-Flame-Logo.wine.svg"
                alt=""
                className="news-card__image"
              />
              <div className="news-card__text-wrapper">
                <h2 className="news-card__title">Meetme</h2>
                <div className="news-card__details-wrapper">
                  <p className="news-card__excerpt">
                    A tinder like application developped with React
                  </p>
                  {/* <a href="#" className="news-card__read-more">
                    Read more <i className="fas fa-long-arrow-alt-right"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div className="news-card">
              <a
                href="https://github.com/FlorianFanor/"
                className="news-card__card-link"
              ></a>
              <img
                src="https://wpformation.com/wp-content/uploads/2014/03/todo1.jpg"
                alt=""
                className="news-card__image"
              />
              <div className="news-card__text-wrapper">
                <h2 className="news-card__title">Todo</h2>
                <div className="news-card__post-date">2021</div>
                <div className="news-card__details-wrapper">
                  <p className="news-card__excerpt">
                    A todo list developped using React, this project was built
                    on a TDD format. The code is available on my github account
                  </p>
                  {/* <a href="#" className="news-card__read-more">
                    Read more <i className="fas fa-long-arrow-alt-right"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
