import "../stylesheet/Timeline.css";
import "../stylesheet/ProfileCard.css";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import BuildIcon from "@material-ui/icons/Build";
import React from "react";
import CodeIcon from "@material-ui/icons/Code";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";

const Timelines = () => {
  return (
    <div id="timeline" className="timeline__container">
      <div className="timeline__left">
        <div className="timeline__title">About me</div>
        <div className="cards-container">
          <div className=" card-one">
            <header>
              <div className="avatar">
                <img
                  src="https://media.istockphoto.com/vectors/user-sign-icon-person-symbol-human-avatar-vector-id639085642?k=20&m=639085642&s=170667a&w=0&h=Oz2wAbb8r_b8sU8k4yZ3RR4NRbe-s7GF0kxjs1aez9M="
                  alt="Florian Fanor"
                />
              </div>
            </header>

            <h3>Florian Fanor</h3>
            <div className="desc">
              I am a fullstack developer with 4 years of experience in a wide
              range of disciplines.
            </div>
            <div className="contacts">
              <a href="">
                <i className="fa fa-plus"></i>
              </a>
              <a href="">
                <i className="fa fa-whatsapp"></i>
              </a>
              <a href="">
                <i className="fa fa-envelope"></i>
              </a>
              <div className="clear"></div>
            </div>

            <footer>
              <a href="">
                <i className="fa fa-facebook"></i>
              </a>
              <a href="">
                <i className="fa fa-linkedin"></i>
              </a>
              <a href="">
                <i className="fa fa-twitter"></i>
              </a>
              <a href="">
                <i className="fa fa-instagram"></i>
              </a>
            </footer>
          </div>

          <div className="clear"></div>
        </div>
      </div>
      <div className="timeline__right">
        {/* <h2>A clean timeline design, with animated links</h2> */}
        <div className="timeline">
          <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type3">
            <div className="timeline__event__icon ">
              <i className="lni-cake"></i>
            </div>
            <div className="timeline__event__date">2017</div>
            <div className="timeline__event__content ">
              <div className="timeline__event__title">Backend developer</div>
              <div className="timeline__event__description">
                <p>
                  I started my journey as a backend .NET developer working on
                  various web applications and integrating them to CMS such as
                  umbraco and sitecore. I was working mainly on .NET framework
                  and MVC
                </p>
              </div>
            </div>
          </div>
          <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
            <div className="timeline__event__icon">
              <i className="lni-burger"></i>
            </div>
            <div className="timeline__event__date">2018</div>
            <div className="timeline__event__content">
              <div className="timeline__event__title">Frontend developper</div>
              <div className="timeline__event__description">
                <p>
                  Few months later I was given the chance to join the Frontend
                  team to help out on the same project, willing to take new
                  challenges and aiming to become a fullstack developer I gladly
                  accepted. where i learnt the basics of HTML, CSS and
                  Javascript
                </p>
              </div>
            </div>
          </div>
          <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
            <div className="timeline__event__icon">
              <i className="lni-slim"></i>
            </div>
            <div className="timeline__event__date">2019</div>
            <div className="timeline__event__content">
              <div className="timeline__event__title">Fullstack developer</div>
              <div className="timeline__event__description">
                <p>
                  In 2019, with that willing to learn new technologies, I took
                  the chance to become a fullstack developer in .NET
                  core/Angular 7.
                </p>
              </div>
            </div>
          </div>
          <div className="timeline__event animated fadeInUp timeline__event--type2">
            <div className="timeline__event__icon">
              <i className="lni-cake"></i>
            </div>
            <div className="timeline__event__date">2019</div>
            <div className="timeline__event__content">
              <div className="timeline__event__title">Fullstack developer</div>
              <div className="timeline__event__description">
                <p>
                  Finally, I intrgrated a team to start a project from scratch
                  using React/React Native and python/Django{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timelines;
