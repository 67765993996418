import { Helmet } from "react-helmet";
import "./App.css";
import MainContent from "./components/MainContent";

function App() {
  return (
    <div>
      <Helmet>
        <title>Florian Fanor</title>
        <meta name="description" content="Florian Fanor portfolio page" />
        <meta
          name="keywords"
          content="Florian, Fanor, Portfolio, Software Engineer"
        />
      </Helmet>
      <h1 className="hidden">Florian Fanor</h1>
      <MainContent />
    </div>
  );
}

export default App;
